import TableCard from "../../../components/Card/TableCard";
import "../../../components/Card/TableCard.css";
import { useEffect, useState } from "react";
import { open_api_with_user_token } from "../../../Utils/network";
import CustomAlert from "../../../components/Alerts/CustomAlert";
import ShareModal from "../../../components/Dialog/ShareModal";

export default function LeadsCapture() {
    const [tableData, setTableData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [alertMessage, setAlertMessage] = useState("");
    const [totalPages, setTotalPages] = useState(1);
    const [totalEntries, setTotalEntries] = useState(0);
    const [isLoading, setLoading] = useState(false);
    const [share,setShare] = useState(false);
    const [url,setUrl] = useState("");

    const columns = [
        { header: "Title", accessor: "title" },
        { header: "Type", accessor: "type" },
        { header: "Created", accessor: "created" },
        { header: "Active", accessor: "active" }
    ];

    const isValidResponse = async ()=>{
        const result = await open_api_with_user_token.get("lead/dashboard/lead-capture-pages/");
        if ((result.status === 200 || result.status === 201) && (result.data !== null && result.data !== undefined)) {
            setTableData(result.data);
            setTotalPages(Math.ceil(result.data.length / 10))
            setTotalEntries(result.data.length)
        }
    }

    const handleShare = (row) => {
        if (row.active) {
          setShare(true); 
          setUrl(`https://www.myburgo.com/${row.expert_slug}/ebook/${row.type}`)
        } else {
          setAlertMessage("Lead page is not active. Please set it to active and then click on share");
        }
      };

   const handleOnClose = () => {
       setShare(false);
     };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };
    
    useEffect(()=>{
       isValidResponse();
    },[])


    return (
        <>
            <div className="leads">
                <p className="subtitle">Leads Capture</p>
                {share && <ShareModal onClose={handleOnClose} shareLink={url} />}
                {alertMessage!=="" && (
                  <CustomAlert
                    type="warning"
                    message={alertMessage}
                    onClose={() => setAlertMessage("")}
                  />
                )}
                
                <div className="all-bookings-table-container">
                    <TableCard
                        columns={columns}
                        data={tableData}
                        totalEntries={totalEntries}
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                        isClickable={true}
                        loading={isLoading}
                        isShowJoinButton={false}
                        isSharable={true}
                        onShare={handleShare}
                    />
                </div>
            </div>

        </>
    )
}