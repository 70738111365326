import React from "react";
import "./TableCard.css";
import { Link } from "react-router-dom";
import emptyIcon from "../../assets/Icons/emptyIcon.svg";
import { formatDate } from "../../Utils/helper";
import LoadingOverlay from "../Loading/LoadingOverlay";
import shareIcon from "../../assets/Icons/shareIcon.svg";
import acceptIcon from "../../assets/Icons/accept.svg";
import rejectIcon from "../../assets/Icons/reject.svg";
import { useLocation } from "react-router-dom";

const TableCard = ({
  columns,
  data,
  totalEntries,
  currentPage,
  totalPages,
  onPageChange,
  isClickable,
  loading,
  isShowJoinButton,
  isSharable,
  onShare
}) => {
  const entriesPerPage = 10; // Set entries per page to 10

  const entriesStart = (currentPage - 1) * entriesPerPage + 1;
  const entriesEnd = Math.min(currentPage * entriesPerPage, totalEntries);
  const currPage = data.slice(entriesStart - 1, currentPage * entriesPerPage);

  const { pathname } = useLocation();
  const route = pathname.split("/");

  return (
    <div className="table-card">
      {loading ? (
        <LoadingOverlay />
      ) : (
        <div className="table-container">
          <div className="table-header">
            {columns.map((col, index) => (
              <div key={index} className="table-header-cell">
                {col.header}
              </div>
            ))}
            {isShowJoinButton || isSharable ? (
              <div className="table-header-cell">Actions</div>
            ) : null}
          </div>

          <div className="table-body">
            {currPage.length > 0 ? (
              currPage.map((row, rowIndex) => {
                const uniqueRowKey = `${row.id || rowIndex}-${rowIndex}`;
                const rowContent = (
                  <div
                    key={uniqueRowKey}
                    className={`table-row ${isClickable ? "clickable" : ""}`}
                  >
                    {columns.map((col, colIndex) => (
                      <div
                        key={`${uniqueRowKey}-${colIndex}`}
                        className="table-cell-row"
                      >
                        {col.accessor === "status" ? (
                          <span
                            className={`status-badge ${row[
                              col.accessor
                            ]?.toLowerCase()}`}
                          >
                            {row[col.accessor]}
                          </span>
                        ) : col.accessor === "created" ? (
                          formatDate(row[col.accessor])
                        ) : col.accessor === "active" ? (
                          <span>
                            <img
                              src={row[col.accessor] ? acceptIcon : rejectIcon}
                              alt={row[col.accessor] ? "Active" : "Inactive"}
                            />
                          </span>
                        ) : (
                          row[col.accessor]
                        )}
                      </div>
                    ))}
                    {isShowJoinButton ? (
                      <div className="table-cell join-button-cell">
                        <button className="join-button">Join</button>
                      </div>
                    ) : null}
                    {isSharable ? (
                      <div className="table-cell share-button-cell">
                        <button
                          onClick={() => {
                            onShare(row);
                          }}
                          className="share-button"
                        >
                          <img src={shareIcon} alt="Share" />
                        </button>
                      </div>
                    ) : null}
                  </div>
                );

                return isClickable ? (
                  <Link
                    to={`/${route[1]}/details/${row.id}`}
                    key={`link-${uniqueRowKey}`}
                    className={`table-row-link ${
                      isClickable ? "clickable" : ""
                    }`}
                  >
                    {rowContent}
                  </Link>
                ) : (
                  <div
                    key={`link-${uniqueRowKey}`}
                    className="table-row-link-disabled"
                  >
                    {rowContent}
                  </div>
                );
              })
            ) : (
              <div className="empty-icon">
                <img src={emptyIcon} alt="No data" />
                <p>No bookings yet</p>
              </div>
            )}
          </div>
        </div>
      )}

      {!loading && (
        <div className="table-footer">
          <div className="table-footer-left">
            Showing {entriesStart} to {entriesEnd} of {totalEntries} entries
          </div>
          <div className="table-footer-right">
            <button
              disabled={currentPage === 1}
              onClick={() => onPageChange(currentPage - 1)}
            >
              &lt;
            </button>
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index}
                className={currentPage === index + 1 ? "active" : ""}
                onClick={() => onPageChange(index + 1)}
              >
                {index + 1}
              </button>
            ))}
            <button
              disabled={currentPage === totalPages}
              onClick={() => onPageChange(currentPage + 1)}
            >
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TableCard;
