import StatsCard from "../../../components/Card/StatsCard";
import LeadsTable from "../../../components/Card/LeadsTable";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { open_api_with_user_token } from "../../../Utils/network";
import noOfLeads from "../../../assets/Icons/noOfLeads.svg";
import timer from "../../../assets/Icons/Timer.svg";
import { formatDate } from "../../../Utils/helper";
import ShareModal from "../../../components/Dialog/ShareModal";
import CustomAlert from "../../../components/Alerts/CustomAlert";

export default function LeadsDetails() {
  const [leadsStats, setLeadsStats] = useState([]);
  const [share, setShare] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const { id } = useParams();
  const columns = [
    { header: "Title", accessor: "title" },
    { header: "Type", accessor: "type" },
    { header: "Active", accessor: "active" },
    { header: "" , accessor:"share"}
  ];
  const [tableData, setTableData] = useState([]);
  const [url, setUrl] = useState("");
  
  const [toggleState,setToggleState] = useState(false)

  const handleShare = (row) => {
    if (row.active) {
      setShare(true); 
      setUrl(`https://www.myburgo.com/${row.expert_slug}/ebook/${row.type}`)
    } else {
      setAlertMessage("Lead page is not active. Please set it to active and then click on share");
    }
  };

  const setData = (data)=>{
     setTableData(data);
  }

  const handleOnClose = () => {
    setShare(false);
    setAlertMessage("");
  };
  
  
 
  const getLeadsDetails = async () => {
    const result = await open_api_with_user_token.get(
      `/lead/dashboard/lead-capture-pages/${id}/`
    );
    if ( (result.status === 200 || result.status === 201) && result.data !== null && result.data !== undefined) {
        
      setTableData([result.data]);
      setStats(result.data);
      setToggleState(result.data.active)
    }
  };

  const setStats = (result) => {
    setLeadsStats([
      {
        icon: noOfLeads,
        title: "Total number of leads captured",
        value: result.total_captured_leads,
      },
      {
        icon: timer,
        title: "Published on date",
        value: formatDate(result.created),
      }
    ]);
  };

  useEffect(() => {
    getLeadsDetails();
  }, [id]);

  return (
    <>
      <div className="leads">
        {share && <ShareModal onClose={handleOnClose} shareLink={url} />}
        {alertMessage!=="" && (
          <CustomAlert
            type="warning"
            title={"Not an Active lead"}
            message={alertMessage}
            onClose={() => setAlertMessage("")}
          />
        )}
        <div className="stats-card-container-leads">
        <StatsCard data={leadsStats} />
         </div>
        
         <div className="all-bookings-table-container">

        <p className="subtitle">Lead Captured Page details</p>
        <LeadsTable
          columns={columns}
          data={tableData}
          isClickable={true}
          loading={false}
          isSharable={true}
          onShare={handleShare}
          toggle={true}
          isActive={toggleState}
          setData = {setData}
        />
        </div>
      </div>
    </>
  );
}
