import React, { useState, forwardRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DateRangeChip.css"; // Style file for the date range chip
import { FaCalendarAlt } from "react-icons/fa"; // Calendar icon

const DateRangeChip = ({ label, onDateChange }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleDateChange = (dates) => {
    const [start, end] = dates;

    // Normalize the dates to the start of the day for startDate and end of the day for endDate
    const normalizedStart = start
      ? new Date(
          start.getFullYear(),
          start.getMonth(),
          start.getDate(),
          0,
          0,
          0,
          0
        )
      : null;
    const normalizedEnd = end
      ? new Date(
          end.getFullYear(),
          end.getMonth(),
          end.getDate(),
          23,
          59,
          59,
          999
        )
      : null;

    setStartDate(normalizedStart);
    setEndDate(normalizedEnd);

    if (onDateChange) onDateChange(normalizedStart, normalizedEnd); // Pass the normalized date range
  };
 


  // Custom input for the date picker
  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <div className="date-range-chip" onClick={onClick} ref={ref}>
      <FaCalendarAlt className="date-range-icon" />
      <span className="date-range-text">
        {value || label} {/* Show selected date range or placeholder */}
      </span>
    </div>
  ));

  return (
    <DatePicker
      selected={startDate}
      onChange={handleDateChange}
      startDate={startDate}
      endDate={endDate}
      selectsRange
      dateFormat="dd/MM/yyyy"
      customInput={<CustomInput />}
      isClearable
      placeholderText={label}
    />
  );
};

export default DateRangeChip;
