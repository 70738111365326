import { useState,useEffect } from "react"
import StatsCard from "../../../components/Card/StatsCard";
import "../LeadsDarshboard/LeadsDashBoard.css"
import { open_api_with_user_token } from "../../../Utils/network";
import LeadsTable from "../../../components/Card/LeadsTable";
import leadCapture from "../../../assets/Icons/leadCapture.svg"
import noOfLeads from "../../../assets/Icons/noOfLeads.svg"
import leadCapturedToday from "../../../assets/Icons/leadCapturedToday.svg"

export default function LeadsDashboard(){
    const [leadsStats,setLeadsStats] = useState([]);
    const [tableData,setTableData] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const columns = [
        { header: "Name", accessor: "name" },
        { header: "Email", accessor: "email" },
        { header: "Phone", accessor: "phone" },
        { header: "Capture Lead from", accessor: "type" },
        { header: "Date", accessor: "created" },
      ];
    const getLeadsStats = async ()=>{
       const result =  await open_api_with_user_token.get("/lead/dashboard/leads-stats/")
       if(result.status == 200 && result.data != null){
        setLeadsStats([{
            icon: noOfLeads,
            title: "Total number of leads",
            value: result.data.total_lead_capture_pages,
          },
          {
            icon: leadCapture,
            title: "Lead Capture pages",
            value: result.data.total_leads
            ,
          },
          {
            icon: leadCapturedToday,
            title: "Lead Captured today",
            value: result.data.total_leads_today
            ,
          }]);
       }
       
    }

    const getLeadsRecent = async ()=>{
        const result =  await open_api_with_user_token.get("/lead/dashboard/leads-recent/")
        setTableData(result.data);
        
     }
    useEffect(()=> {
        getLeadsStats();
        getLeadsRecent();
        
    },[])
    return(
        <>
        <div className="leads">
            
            <div className="stats-card-container-leads"> 
            <p className="subtitle">Leads</p>
            <StatsCard data={leadsStats} ></StatsCard>
            </div>
            <div className="all-bookings-table-container">

            <p className="subtitle">Recently Captured Leads</p>
            <LeadsTable
            columns={columns}
            data={tableData}
            isClickable={true}
            loading={isLoading}
            isShowJoinButton={true}
          />
            </div>
            

        </div>
        </>
    )
}