import TableCard from "../../../components/Card/TableCard";
import "../../../components/Card/TableCard.css";
import FilterChip from "../../../components/Chip/FilterChip";
import DateRangeChip from "../../../components/Chip/DateRangeChip";
import SearchBar from "../../../components/Search/SearchBar";
import { useEffect, useState } from "react";
import { open_api_with_user_token } from "../../../Utils/network";
import "../../Leads/LeadsDarshboard/LeadsDashBoard.css"
import "../../Leads/AllLeads/AllLeads.css"
import Chips from "../../../components/Chip/Chips";
const XLSX = require('xlsx');

export default function AllLeads() {
    const [selectedOption, setSelectedOption] = useState("All");
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [tableData, setTableData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalEntries, setTotalEntries] = useState(0);
    const [options, setOptions] = useState(["All"]);
    const [isLoading, setLoading] = useState(false);

    const columns = [
        { header: "Name", accessor: "name" },
        { header: "Email", accessor: "email" },
        { header: "Phone", accessor: "phone" },
        { header: "Capture Lead from", accessor: "type" },
        { header: "Date", accessor: "created" },
    ];

    const isValidResponse = (result)=>{
        if ((result.status === 200 || result.status === 201) && (result.data !== null && result.data !== undefined)) {
            setTableData(result.data);
            setTotalPages(Math.ceil(result.data.length / 10))
            setTotalEntries(result.data.length)
        }
    }

    const getType = async()=>{
        const result = await open_api_with_user_token.get(`/lead/dashboard/leads-filter/`);
        const filteredData = new Set(result.data.map((res) => res.type));
        const data = [...filteredData]
        setOptions(["All", ...data]);
    }

    const filters = async () => {        
        if (selectedOption === "All") {
            const result = await open_api_with_user_token.get(`/lead/dashboard/leads-filter/?start_date=${(startDate !==null) ? startDate : ""}&end_date=${(endDate !==null) ? endDate : ""}&search=${searchQuery}`);
            isValidResponse(result);
            
        }
        else {
            const result = await open_api_with_user_token.get(`/lead/dashboard/leads-filter/?type=${selectedOption}&start_date=${(startDate !==null) ? startDate : ""}&end_date=${(endDate !==null) ? endDate : ""}&search=${searchQuery}`);
            isValidResponse(result);
        }
    }

    const createDateString = (date)=>{
        const required_date = new Date(date);
        const month = (required_date.getMonth() + 1 < 10) ? `0${required_date.getMonth() + 1}` : required_date.getMonth() + 1
        const day = (required_date.getDate() < 10) ? `0${required_date.getDate()}` : required_date.getDate()
        const date_string = `${required_date.getFullYear()}-${month}-${day}`
        return date_string;
    }


    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleSelect = (option) => {
        setSelectedOption(option);
        setCurrentPage(1);
    };

    const handleDateChange = (start, end) => {
        const updatedStartDate = start !== null ? createDateString(start) : null;
        const updatedEndDate = end !== null ? createDateString(end) : null;

        setStartDate(updatedStartDate);
        setEndDate(updatedEndDate);
        setCurrentPage(1);
    };

    const generateExport = ()=>{
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(tableData);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Leads Data');
        XLSX.writeFile(workbook, 'Leads Data.xlsx');      
    }

    const handleSearch = (query) => {
        setSearchQuery(query);
        setCurrentPage(1);
    };
    useEffect(()=>{
        getType();
    },[])

    useEffect(() => {
        filters();
    }, [selectedOption, searchQuery, startDate, endDate])

    return (
        <>
            <div className="leads">
                <p className="subtitle">All Leads</p>
                <div className="leads-filters">
                    <div className="filters-container">
                        <FilterChip
                            options={options}
                            selected={selectedOption}
                            onSelect={handleSelect}
                        />
                        <DateRangeChip
                            label="Select Date Range"
                            onDateChange={handleDateChange}
                        />
                        <Chips 
                         icon={""}
                         text={"Export leads"}
                         onClick={generateExport}>
                        </Chips>
                    </div>
                    <div className="search-container">
                        <SearchBar onSearch={handleSearch} />
                    </div>
                </div>
                <div className="all-bookings-table-container">
                    <TableCard
                        columns={columns}
                        data={tableData}
                        totalEntries={totalEntries}
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                        isClickable={false}
                        loading={isLoading}
                        isShowJoinButton={false}
                    />
                </div>
            </div>

        </>
    )
}