import React, { useState } from "react";
import "./FilterChip.css"; // Style file for the filter chip
import { FaChevronDown } from "react-icons/fa";

const FilterChip = ({ options, selected, onSelect }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (option) => {
    onSelect(option);
    setIsOpen(false); // Close dropdown after selection
  };

  return (
    <div className="custom-dropdown">
      <div className="dropdown-header" onClick={handleToggle}>
        <span className="filter-span">{selected}</span>
        <FaChevronDown className={`dropdown-icon ${isOpen ? "open" : ""}`} />
      </div>
      {isOpen && (
        <div className="dropdown-list">
          {options.map((option, index) => (
            <div
              key={index}
              className="dropdown-item"
              onClick={() => handleSelect(option)}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default FilterChip;
