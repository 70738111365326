import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import DashboardLayout from "../layouts/DashboardLayout";
import Dashboard from "../pages/Dashboard/Dashboard";
import BookingsDashboard from "../pages/Bookings/BookingsDashboard/BookingsDashboardPage";
import AllBookingsPage from "../pages/Bookings/AllBookings/AllBookingsPage";
import BookingDetailsPage from "../pages/Bookings/BookingDetails/BookingDetailsPage";
import AllPackage from "../pages/Bookings/BookingsPackage/AllPackage";
import AddPackage from "../pages/Bookings/BookingsPackage/AddPackage";
import AllSlots from "../pages/Bookings/BookingsSlot/AllSlots";
import AllQuestions from "../pages/Bookings/BookingQuestions/AllQuestions";
import AddQuestions from "../pages/Bookings/BookingQuestions/AddQuestions";
import BookingSettings from "../pages/Settings/BookingSetting/BookingSettings";
import LoginPage from "../pages/Login/LoginPage";
import PrivateRoute from "./PrivateRoute";
import { useLocation } from "react-router-dom";
import MobileHeader from "../components/Header/MobileHeader";

import AllLeads from "../pages/Leads/AllLeads/AllLeads";
import LeadsDashboard from "../pages/Leads/LeadsDarshboard/LeadsDashBoard";
import LeadsCapture from "../pages/Leads/LeadsCapture/LeadsCapture";
import LeadsDetails from "../pages/Leads/LeadsDetails/LeadsDetails";

const MainRouter = () => {
  const location = useLocation(); // Hook to get the current route

  // List of routes where the header should not appear
  const excludedRoutes = ["/login"];

  const shouldShowHeader = !excludedRoutes.includes(location.pathname);

  return (
    <>
      {shouldShowHeader && <MobileHeader />}
      <Routes>
        {/* Public Route */}
        <Route path="/login" element={<LoginPage />} />

        {/* Protected Routes */}
        <Route
          path="/"
          element={
            <PrivateRoute>
              <DashboardLayout />
            </PrivateRoute>
          }
        >
          {/* Dashboard Home */}
          <Route index element={<Dashboard />} /> {/* Renders for path="/" */}
          {/* Bookings Nested Routes */}
          <Route path="bookings">
            <Route path="dashboard" element={<BookingsDashboard />} />
            <Route path="all" element={<AllBookingsPage />} />
            <Route path="details/:id" element={<BookingDetailsPage />} />
            <Route path="packages" element={<AllPackage />} />
            <Route path="packages/add" element={<AddPackage />} />
            <Route path="slots" element={<AllSlots />} />
            <Route path="questions" element={<AllQuestions />} />
            <Route path="questions/add" element={<AddQuestions />} />
            <Route path="settings" element={<BookingSettings />} />
          </Route>

          <Route path="leads">
             <Route path="all" element={<AllLeads />} />
             <Route path="dashboard" element={<LeadsDashboard />} />
             {/* {/* <Route path="all" element={<AllLeads />} /> */}
             <Route path="leadscapture" element={<LeadsCapture />} /> 
             <Route path="details/:id" element={<LeadsDetails />}/>
          </Route>
        </Route>
      </Routes>
    </>
  );
};

export default MainRouter;
