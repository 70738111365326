import React, { useEffect, useState } from "react";
import "./TableCard.css";
import { Link } from "react-router-dom";
import emptyIcon from "../../assets/Icons/emptyIcon.svg";
import LoadingOverlay from "../Loading/LoadingOverlay";
import { formatDate } from "../../Utils/helper";
import shareIcon from "../../assets/Icons/shareIcon.svg"
import ToggleButton from "../Button/ToggleButton";
import { open_api_with_user_token } from "../../Utils/network";
import { useParams } from "react-router-dom";


const LeadsTable = ({
  columns,
  data,
  isClickable,
  loading,
  onShare,
  isActive,
  setData
}) => {

  const [toggleState,setToggleState] = useState(isActive)
  const {id} = useParams()
  const handleToggle = async()=>{
      
    setToggleState((prevState) => {
      const newToggleState = !prevState
      const updateActiveStatus = async () => {
        try {
          const result = await open_api_with_user_token.patch(
            `/lead/dashboard/lead-capture-pages/${id}/`,
            { active: newToggleState }
          );

          const updatedData = result.data;
          setData([updatedData]); 
        } catch (error) {
          console.error("Error updating toggle state:", error);
        }
      };

      updateActiveStatus();
      return newToggleState; 
    });
  }

  useEffect(()=>{
    setToggleState(isActive);
  },[isActive])


  return (
    <div className="table-card table-leads">
      {loading ? (
        <LoadingOverlay />
      ) : (
        <div className="table-container">
          <div className="table-header">
            {columns.map((col, index) => (
              <div key={index} className="table-header-cell">
                {col.header}
              </div>
            ))}
          </div>

          <div className="table-body">
            {data.length > 0 ? (
              data.map((row, rowIndex) => {
                const uniqueRowKey = `${row.id || rowIndex}-${rowIndex}`;
                const rowContent = (
                  <div
                    key={uniqueRowKey}
                    className={`table-row leads-row ${isClickable ? "clickable" : ""}`}
                  >
                    {columns.map((col, colIndex) => (
                      <div
                        key={`${uniqueRowKey}-${colIndex}`}
                        className="table-cell-row"
                      >
                        {col.accessor === "created" ? (
                          <span>
                              {(() => {                              
                                 return formatDate(row[col.accessor]);
                               })()}
                          </span>
                        ) : (
                          col.accessor == "active" ?                          
                            <button className="share-button">
                            <ToggleButton  isToggled={toggleState} onToggle={handleToggle}/>
                            </button> 
                            :
                            col.accessor == "share"?                        
                          <button onClick={()=>onShare(row)} className="share-button">
                             <img src={shareIcon}/>
                          </button>                        
                          :
                          row[col.accessor]
                        )}
                      </div>
                    ))}
                   
                       
                  </div>
                );
                return  <div
                    key={`link-${uniqueRowKey}`}
                    className="table-row-link-disabled"
                  >
                    {rowContent}
                  </div>
            
              })
            ) : (
              <div className="empty-icon">
                <img src={emptyIcon} alt="No data" />
                <p>No bookings yet</p>
              </div>
            )}
          </div>
        </div>
      )}

    </div>
  );
};

export default LeadsTable;
